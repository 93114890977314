













import { Component, Vue } from "vue-property-decorator";

@Component
export default class ColorCards extends Vue {
  private brandId = -1;
  private brandList = [
    {
      id: "polarBear",
      name: "北极熊",
      pages: [
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_01.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_02.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_03.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_04.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_05.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_06.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_07.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_08.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_09.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_10.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_11.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_12.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_13.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_14.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_15.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_16.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_17.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_18.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_19.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_20.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_21.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_22.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_23.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_24.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_25.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_26.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_27.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_28.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_29.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_30.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_31.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/polarBear/polarBear_32.jpg",
      ],
    },
    {
      id: "snowAngeles",
      name: "大雪杉",
      pages: [
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_01.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_02.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_03.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_04.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_05.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_06.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_07.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_08.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_09.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_10.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_11.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_12.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_13.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_14.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_15.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_16.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_17.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_18.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_19.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_20.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_21.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_22.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/snowAngeles/snowAngeles_23.jpg",
      ],
    },

    {
      id: "solidWood",
      name: "新实木",
      pages: [
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_01.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_02.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_03.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_04.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_05.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_06.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_07.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_08.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_09.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_10.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_11.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_12.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_13.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_14.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_15.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_16.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_17.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_18.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_19.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_20.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_21.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_22.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_48.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_24.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_25.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_26.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_27.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_28.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_29.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_30.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_31.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_32.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_33.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_34.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_35.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_36.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_37.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_38.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_39.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_40.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_41.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_42.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_43.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_45.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_46.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_47.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_44.jpg",
      ],
    },
    {
      id: "chongqing",
      name: "重庆色卡",
      pages: [
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_01.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_02.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_03.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_37.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_28.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_42.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_29.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_43.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_35.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_39.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_38.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_36.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_41.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_24.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_15.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_16.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_11.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_05.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_14.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_08.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_07.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_12.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_04.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_06.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_48.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_20.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_22.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_31.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_33.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_32.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_18.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_45.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_46.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_47.jpg",
        "https://eggrj.oss-cn-hangzhou.aliyuncs.com/shoppingGuide/colorCards/solidWood/solidWood_44.jpg",
      ],
    },
  ];

  get curColor(): any {
    return this.brandId > -1 ? this.brandList[this.brandId] : null;
  }

  mounted(): void {
    this.brandId = this.brandList.findIndex((item) => {
      return item.id === this.$route.params.id;
    });

    if (this.curColor) {
      document.title = this.curColor.name + "-电子色卡";
    }
    if (this.curColor.id == "chongqing") {
      document.title = "柜柜云仓-" + this.curColor.name;
    }
  }
}
